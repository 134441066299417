








































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent, ValueFormatterParams } from '@ag-grid-community/core';
import { meanBy, round, sumBy } from 'lodash';
import DateRangeFilter from '@/components/date-range-filter.vue';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import SaleService from '@/services/crm/sale.service';
import SaleByCollectionModel from '@/models/crm/sale-by-collection.model';
import { NumberHelper } from '@/utils/helpers/number-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';

type DataGridFilterConfig = {
  keyword: string | undefined;
};

type FeaturedCard = {
  title: string;
  value: string;
  class?: string;
  cols: {
    lg: number;
    md: number;
  };
};

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
  },
})
export default class CrmSales extends Vue {
  @inject(InjectionIdEnum.CrmSaleService)
  private saleService!: SaleService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'codColecao', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.sales.grid.collectionName')}`,
        field: 'colecao',
        type: 'leftAligned',
        minWidth: 130,
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.orderQuantity')}`,
        field: 'qtdePedidos',
        type: 'rightAligned',
        cellStyle: { justifyContent: 'flex-end' },
        maxWidth: 100,
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.grossValue')}`,
        field: 'valorBruto',
        type: 'leftAligned',
        maxWidth: 140,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.netValue')}`,
        field: 'valorLiquido',
        type: 'leftAligned',
        maxWidth: 140,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.discount')}`,
        field: 'valorDesconto',
        type: 'leftAligned',
        maxWidth: 170,
        valueFormatter: (params: ValueFormatterParams): string => {
          const formattedValue = NumberHelper.formatToLocaleDecimal(params.value);

          const isRowPinned = params.node && params.node.rowPinned;

          if (isRowPinned) {
            return formattedValue;
          }

          const formattedPercentage = Number.isInteger(params.data.percDesconto)
            ? params.data.percDesconto
            : NumberHelper.formatToLocaleDecimal(params.data.percDesconto);
          return `${formattedValue}(${formattedPercentage}%)`;
        },
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.discountPercentage')}`,
        field: 'percDesconto',
        type: 'leftAligned',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.commission')}`,
        field: 'valorComissao',
        type: 'leftAligned',
        maxWidth: 170,
        valueFormatter: (params: ValueFormatterParams): string => {
          const formattedValue = NumberHelper.formatToLocaleDecimal(params.value);
          const isRowPinned = params.node && params.node.rowPinned;

          if (isRowPinned) {
            return formattedValue;
          }

          const formattedPercentage = Number.isInteger(params.data.percComissao)
            ? params.data.percComissao
            : NumberHelper.formatToLocaleDecimal(params.data.percComissao);
          return `${formattedValue}(${formattedPercentage}%)`;
        },
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.commissionPercentage')}`,
        field: 'percComissao',
        type: 'leftAligned',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.paymentTermAverage')}`,
        field: 'prazoMedio',
        type: 'leftAligned',
        maxWidth: 140,
        valueFormatter: (params: ValueFormatterParams): string => {
          const formattedValue = NumberHelper.formatToLocaleDecimal(params.value);
          const isRowPinned = params.node && params.node.rowPinned;
          return isRowPinned ? '' : formattedValue;
        },
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.averageTicket')}`,
        field: 'ticketMedio',
        type: 'leftAligned',
        maxWidth: 140,
        valueFormatter: (params: ValueFormatterParams): string => {
          const formattedValue = NumberHelper.formatToLocaleDecimal(params.value);
          const isRowPinned = params.node && params.node.rowPinned;
          return isRowPinned ? '' : formattedValue;
        },
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.itemsAverage')}`,
        field: 'quantidade',
        type: 'leftAligned',
        maxWidth: 140,
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.totalItemsQuantity')}`,
        field: 'quantidade',
        type: 'leftAligned',
        maxWidth: 140,
      },
      {
        headerName: `${this.$t('crm.view.sales.grid.avaregeTicketPc')}`,
        field: 'ticketMedioPc',
        type: 'leftAligned',
        maxWidth: 140,
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
  };

  items: SaleByCollectionModel[] = [];

  selected: SaleByCollectionModel[] = [];

  async mounted(): Promise<void> {
    this.loadItems();
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: SaleByCollectionModel[]): void {
    this.selected = selected;
  }

  onExport(selected: SaleByCollectionModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Vendas por Coleção',
        fileName: SaleService.generateSalesByCollectionExportFilename(new Date()),
      });
    }
  }

  get filteredItems(): SaleByCollectionModel[] {
    let filteredItems = this.items;

    if (this.filters.keyword) {
      const columnsToSearch = ['colecao'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    return filteredItems;
  }

  get featuredCards(): FeaturedCard[] {
    const items = this.filteredItems;

    const totals = {
      avgOfOrderQuantity: round(meanBy(items, 'qtdePedidos')) || 0,
      avgOfNetValue: meanBy(items, 'valorLiquido') || 0,
      avgOfAverageTicket: meanBy(items, 'ticketMedio') || 0,
      avgOfDiscountAverage: meanBy(items, 'percDesconto') || 0,
      avgOfItemsAverage: round(meanBy(items, 'quantidade')) || 0,
      avgOfPaymentTermAverage: meanBy(items, 'prazoMedio') || 0,
    };

    const parseDecimal = (value: number): string => {
      const isInteger = Number.isInteger(value);
      return isInteger ? value.toString() : NumberHelper.formatToLocaleDecimal(value);
    };

    return [
      {
        title: `${this.$t('crm.view.sales.card.avgOfOrderQuantity')}`,
        value: parseDecimal(totals.avgOfOrderQuantity),
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.sales.card.avgOfNetValue')}`,
        value: NumberHelper.formatToLocaleCurrency(totals.avgOfNetValue),
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.sales.card.avgOfAverageTicket')}`,
        value: NumberHelper.formatToLocaleCurrency(totals.avgOfAverageTicket),
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.sales.card.avgOfDiscountAverage')}`,
        value: parseDecimal(totals.avgOfDiscountAverage),
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.sales.card.avgOfItemsAverage')}`,
        value: parseDecimal(totals.avgOfItemsAverage),
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.sales.card.avgOfPaymentTermAverage')}`,
        value: parseDecimal(totals.avgOfPaymentTermAverage),
        cols: { lg: 2, md: 4 },
      },
    ];
  }

  get summary(): IKeyValue<number>[] | undefined {
    const items = this.filteredItems;

    if (!items || !items.length) {
      return undefined;
    }

    return [
      {
        qtdePedidos: sumBy(items, 'qtdePedidos'),
        valorBruto: sumBy(items, 'valorBruto'),
        valorLiquido: sumBy(items, 'valorLiquido'),
        valorDesconto: sumBy(items, 'valorDesconto'),
        valorComissao: sumBy(items, 'valorComissao'),
      },
    ];
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = await this.saleService.getSalesByCollection(this.getClientIdFromRoute());
    this.gridSettings.loading = false;
  }

  private getClientIdFromRoute(): string {
    if (!this.activeClient) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }
}
